html, body {

}

ul {
  list-style-type: none;
}

body {
  background-color:#fff;
  margin: 0px;
  height: 100%;
  overflow: hidden;
}
#lottie{
  background-color:#fff;
  width:100%;
  height:100%;
  display:block;
  overflow: hidden;
  transform: translate3d(0,0,0);
  text-align: center;
  opacity: 1;
}

.footer {
  // text-align: center;
  .footer-bottom {
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
}

.totop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  a {
    padding: 2rem 3rem;
    display: block;
  }
}

.section-inner {
  width: 98%;
  max-width: 1040px;
  margin: auto;
}

.page-login {
  padding-top: 90px;
  max-width: 480px;
  margin: auto;
  .page-title {
    text-align: center;
  }
  .login-icon {
    font-size: 3rem;
  }
  .button {
    background-color: $dark-blue;
    &:hover {
      background-color: lighten($dark-blue, 10%);
    }
  }
}

.page-post-detail {
  padding-top: 120px;
  .section-header {
    text-align: center;
  }
  .section-content {
    width: 80%;
    max-width: 840px;
    margin: auto;
  }
  .section-post-share {
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center;
    a {
      display: block;
      padding: 1rem;
    }
    i, svg {
      font-size: 3rem;
    }
  }
}

.page-header {
  position: relative;
  background-position: bottom center;
  .page-title-wrapper {
    position: relative;
    width: 100%;
    // height: 40%;
    // min-height: 240px;
  }
  .page-title {
    // position: absolute;
    // right: 0;
    // bottom: 30%;
    // color: #fff;
    // font-weight: 700;
    margin-top: 0;
    text-align: right;
  }
  .banner-title-wrapper {
    position: relative;
    height: 200px;
  }
  .banner-title {
    // width: 90%;
    margin: auto;
    text-align: right;
    padding: 8rem;
    img {
      width: 240px;
    }
    @include phone {
      // padding-right: 4rem;
      padding: 0;
      height: 200px;
      width: 90%;
      position: relative;
      img {
        position: absolute;
        bottom: 1rem;
        right: 0;
        // width: 180px;
        height: 60px;
        width: auto;
      }
    }
  }
  &.page-with-banner {
    padding-bottom: 0;
    .banner-inner {
      background-repeat: no-repeat;
      background-position-x: 30%;
      @include phone {
        background-position-x: 20%;
      }
    }
  }
}

.page-about.page-header {
  .banner-inner {
    background-size: 120%; 
    background-position: 60px 50%;
    @include phone {
      background-position: 5% 0;
      background-size: 180%;
    }
  }
}

.page-yarn.page-header {
  .banner-inner {
    background-size: contain; 
    background-position: 180px 50%;
    @include phone {
      background-position: 50% 25%;
      background-size: 90%;
    }
  }
}

.page-contact.page-header {
  .banner-inner {
    // background-size: contain; 
    // background-position: 180px 50%;
    background-size: 24%; 
    background-position: 240px 50%;
    @include phone {
      background-position: 30% 15%;
      background-size: 40%;
    }
  }
}


.page-gloves.page-header {
  .banner-inner {
    // background-size: contain; 
    // background-position: 180px 50%;
    @include phone {
      background-position: 50% 25%;
      background-size: 90%;
    }
  }
  @include phone {
    .banner-title {
      img {
        // width: 180px;
        height: 60px;
        width: auto;
      }
    }
  }
}


.page-products {
  // padding: 0;
  padding-bottom: 4rem;
  @include phone {
    padding: 1rem 2rem 4rem;
  }
  .block-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
  .product-category {
    text-align: center;
    padding: 1rem;
    background-color: #eee;
    border-radius: 8px;
    .image {
      max-width: 240px;
      margin: auto;
    }
  }
  .subtitle {
    color: $green;
    font-size: 1.3rem;
  }
  .more {
    margin-top: 1rem;
    a {
      background-color: $green;
      color: #fff;
      font-size: 1rem;
      padding: 0.6rem 2rem;
    }
  }
  .description {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 2;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.material-container {
  @include phone {
    padding-top: 30px;
  }
}

.page-yarns, .page-gloves {
  .image {
    max-width: 240px;
    margin: auto;
  }
  .block-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
  .subtitle {
    color: $green;
    font-size: 1.3rem;
  }
}

.page {
  .page-title {
    margin-top: 120px;
    text-align: center;
  }

  .page-content {
    padding: 1rem;
  }
}


.image-carousel {
  margin-top: 3rem;
  .carousel-item {
    padding: 1rem;
  }
}

.page-list-Achievements {
  padding-top: 2rem;
  .section-ssf, .page-title {
    display: none;
  }
  .page-title {
    
  }
  .post-list-item {
    text-align: center;    
  }
  .title {
    font-weight: 700;
    text-transform: capitalize;
  }
  .excerpt {
    color: $green;
  }
}

.page-banner-Achievements {
  .banner-title {
    img {
      width: 340px;
    }
  }
  @include phone {
    .banner-inner {
      background-size: 30%;
      background-position: 0 20%;
    }
    .banner-title {
      
    }
  }
}


.menu-toggle {
  position: fixed;
  top: 0;
  right: 0;
  font-size: 3rem;
  padding: 1rem 2rem;
  cursor: pointer;
}



.screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  // width: 100%;
  // height: 100%;
  background-color: hsla(255, 255, 255, 0.8);
  // background-image: url('../img/terminal.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 998;
  display: none;
}

.menu-screen, .preloader-screen {
  // background-color: transparentize(#000, 0.8);
  // @extend .screen;
}

.mobile-menu-wrapper, .action-menu-wrapper {
  padding-top: 1rem;
  display: none;
  z-index: 999;
  // background-color: rgba(255, 255, 255, 0.8);
  background-color: transparentize($teal, 0.2);
  height: 100vh;
  width: 320px;
  position: fixed;
  top: 0;
  text-transform: capitalize;
  // box-shadow: 0 2px 20px $accent-color;
  box-shadow: 0 2px 20px #000;
  .close-button {
    color: #666;
  }
  @include phone {
    width: 65%;
  }
}

.action-menu-wrapper {
  right: 0;
}

.mobile-menu, .action-menu {
  .menu-top {
    padding: 1rem;
    h3 {
      font-size: 2rem;
      color: #fff;
    }
    a {
      border: none;
    }
  }
  a {
    color: #fff;
    font-size: 2rem;
    font-family: $header-font-family;
    padding: 1rem 1rem;
    display: block;
    // border-bottom: solid 1px #666;
    &:hover {
      background-color: $green;
      color: #fff;
    }
  }
}

nav {
  font-family: $header-font-family;
}

.mobinav {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  text-align: center;
  padding: 1rem;
}

.section-contact {
  padding: 1rem;
}

footer {
  .footer-qr {
    padding: 0.5rem;
    img {
      max-width: 240px;
      margin: auto;
    }
  }
  .footer-social {
    .socials div {
      padding: 3rem 1.5rem;
      i, svg {
        font-size: 3.6rem;
      }
    }
  }
}

.page-contact {
  .page-content {
    max-width: 640px;
    margin: auto;
  }
}


.page {
  .page-content {
    margin: auto;
    max-width: 640px;
    padding: 6rem 1rem;
    @include tablet-down {
      padding: 6rem 4rem;
    }
  }
}


.animation-wrapper {
  position: relative;
  width: 100%;
  .animation-control {
    background-color: transparentize(#fff, 0.8);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    // transform: translateX(-50%);
  }
  .page-title {
    text-align: right;
    display: block;
    font-weight: 700;
    padding-right: 2rem;
    font-size: 5rem;
  }
}

.about-timeline {
  text-align: center;
  margin-bottom: 10rem;
  h2 {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
  .timeline-list {
  }
  .timeline-item {
    position: relative;
    padding-bottom: 3rem;
    > .cell.auto {
      padding: 1rem 2rem;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 3px;
      background: $teal2;
      z-index: -2;
    }
  }
  .timeline-item:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
  }
  .timeline-item:nth-child(even) {
    text-align: left;
  }
  .timeline-item:last-child {
    padding-bottom: 0;
    &::before {
      height: 50%;
    }
  }
  .year {
    background-color: $teal2;
    text-align: center;
    width: 90px;
    height: 90px;
    color: #fff;
    font-weight: 700;
    font-size: 2.4rem;
    align-items: center;
    line-height: 90px;
    border-radius: 50%;
  }
  .desc {
    line-height: 1.4;
    margin: 0 auto;
    h3, h4, .desc {
      margin: 0;
    }
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 700;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: 700;
  }
  p {
    font-size: 1.2rem;
    margin-bottom: 0;
  }

}

.page-flatpage {
  .page-content {
    .description {
      ul {
        list-style-type: disc;
      }
    }
  }
}

.page-banner-gloves {
  .banner-inner {
    background-repeat: no-repeat; 
    background-size: auto 240px; 
    background-position: -30% center;
    @include tablet {
      background-position: 10% center;
    }
    @include phone {
      background-position: 50% center;
      
    }
  } 
}


.page-gloves {
  .description {
    table, tr, td, tbody {
      border: none;
    } 
    table ul {
      margin-bottom: 0;
    }
    tbody tr:nth-child(even) {
      background-color: transparent;
    }
    table td {
      padding: 0;
    }
    table thead, table tbody, table tfoot {
      border: none;
    }
  }
}


.logo-bg {
  @include tablet-down {
    padding: 0;
  }
}

.logo {
  @include tablet-down {
    width: 36px;
  }
}

.breadcrumbs-wrapper {
  padding: 0 1rem;
  max-width: 960px;
  margin: auto;
}