$dark-blue: rgb(0,65,159);


// Sungai: 8efff9
// Langit gradient - 139ac9 - 66cbf5

// logo biru: 2867d9 - 009fed
// hijau: 01ac05
$blue1: #2867d9;
$blue2: #009fed;
$green: #01ac05;

$teal: #1A799B;

$teal2: #50a180;